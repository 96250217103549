<footer class="footer-wrap">
  <div class="footer" *ngIf="isShowFooter">
    <div class="footer-content">
      <div class="d-flex justify-content-end">
        <img
          (click)="handleScrollToTop('smooth')"
          class="footer-logo pointer"
          routerLink="/home"
          [src]="imgLogoGipt"
          alt=""
        />
      </div>
      <div class="footer-content-info d-flex flex-wrap align-item-start m-t-60">
        <div class="d-flex flex-column footer-info-item m-b-16">
          <a href="/auth/sign-in" target="_blank">
            {{ '::Wishlist:Footer:login' | abpLocalization }}
          </a>
          <a href="/auth/sign-up" target="_blank">
            {{ '::Wishlist:Footer:SignUp' | abpLocalization }}
          </a>
        </div>

        <div class="d-flex flex-column footer-info-item m-b-16">
          <a routerLink="/faq">{{ '::Wishlist:Footer:FAQHelp' | abpLocalization }}</a>
          <a routerLink="/contact">{{ '::Wishlist:Footer:Inquiry' | abpLocalization }}</a>
          <a routerLink="/logo-guideline">{{ '::Wishlist:Footer:LogoGuide' | abpLocalization }}</a>
        </div>

        <div
          class="d-flex flex-column footer-info-item"
          [ngClass]="isMobileScreen ? 'm-b-32' : 'm-b-69'"
        >
          <a routerLink="/privacy-policy">
            {{ '::Wishlist:Footer:PrivacyPolicy' | abpLocalization }}
          </a>
          <a routerLink="/terms-of-service">
            {{ '::Wishlist:Footer:TermsOfService' | abpLocalization }}
          </a>
          <a routerLink="/specified-commercial-transaction-act">
            {{ '::Wishlist:Footer:Commercial' | abpLocalization }}
          </a>
        </div>
      </div>

      <hr class="m-t-8 m-b-12" />
      <div class="copyright flex-none">{{ '::SignIn:Copyright' | abpLocalization }}</div>
    </div>
  </div>
  <ng-container *ngIf="screen !== 'home'">
    <div *ngIf="isShowMenuMobile" style="height: 60px"></div>
    <div *ngIf="isShowMenuMobile" class="menu-mobile">
      <div>
        <img
          (click)="changeMenu('/main/wishlist/creator-view')"
          routerLink="/main/wishlist/creator-view"
          src="assets/images/icon/svg/gift-box-icon.svg"
          alt="Avatar"
        />
        <br />
        <img
          *ngIf="isMenuItemActive('/main/wishlist/creator-view')"
          class="img-elipse-mobile"
          src="assets/images/icon/svg/menu-header-elipse.svg"
          alt="Avatar"
        />
      </div>
      <div>
        <img
          (click)="changeMenu('/main/notification')"
          routerLink="/main/notification"
          src="assets/images/icon/svg/message.svg"
          alt="Avatar"
        />
        <br />
        <img
          *ngIf="isMenuItemActive('/main/notification')"
          class="img-elipse-mobile"
          src="assets/images/icon/svg/menu-header-elipse.svg"
          alt="Avatar"
        />
      </div>
      <div>
        <img
          (click)="changeMenu('/main/creator-search')"
          routerLink="/main/creator-search"
          src="assets/images/icon/svg/filter.svg"
          alt="Avatar"
        />
        <br />
        <img
          *ngIf="isMenuItemActive('/main/creator-search')"
          class="img-elipse-mobile"
          src="assets/images/icon/svg/menu-header-elipse.svg"
          alt="Avatar"
        />
      </div>
      <div (click)="openGiptBox()" class="image-container">
        <img src="assets/images/icon/svg/cart.svg" alt="Image" />
        <p-badge
          *ngIf="giftToltal !== 0"
          class="badge-mobile-custom"
          [value]="giftToltal"
        ></p-badge>
      </div>
    </div>
  </ng-container>

  <app-gipt-box #giptbox></app-gipt-box>
</footer>
